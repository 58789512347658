section.contact-form {
    // padding-top: 200px;
    ::placeholder,
    .form-control {
        font-family: 'proxima-nova', sans-serif;
        font-size: 12px;
        font-weight: 500;
        text-transform: none;
        letter-spacing: 0.15em;
    }
    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 5px !important;
    }
    [id*="btnSend"] {
        max-width: 193px;
    }
    .qc-form-wrapper {
        padding: 100px 155px;
    }
    @media screen and (max-width: 1800px) {
        .qc-form-wrapper {
            padding: 70px 130px;
        }
    }
    @media screen and (max-width: 1600px) {
        .qc-form-wrapper {
            padding: 50px 165px 50px 0;
        }
    }
    @media screen and (max-width: 991px) {
        .qc-form-wrapper {
            margin-bottom: 0;
            padding: 75px 35px !important;
            margin-top: 0;
        }
    }
    @media screen and (max-width: 767px) {
        .qc-form-wrapper {
            padding: 75px 15px !important;
        }
    }
    &.no-services {
        @media screen and (min-width: 992px) {    
            & {
                padding-top: 300px !important;
            }
        }
        
        @media screen and (max-width: 991px) {   
            & {
                padding-top: 0px !important;
            } 
        }
        &.no-testimonials {
            padding-top: 30px !important;
        }
    }

}
